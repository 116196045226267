<template>
    <div>
      <a-card>
        <template slot="title">
          <strong class="mr-4">Daftar Stok</strong>
          <!-- <a-range-picker class="ml-2" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
          <!-- <a-button
            type="button"
            class="btn btn-outline-primar10 ml-2"
  10        @click="connecto10('nonanggota'10"
          >
            Tambah Non Anggota
          </a-button> -->
          <a-button-group>
            <!-- <a-button icon="plus-circle" class="text-primary" type="" @click="showModal('create', {})">
              Tambah
            </a-button> -->
            <a-tooltip placement="top">
              <template slot="title">
                <span>Refresh Data</span>
              </template>
              <a-button @click="getMaster">
                <a-icon type="sync" :spin="loadingRefresh" class="text-success"/>
              </a-button>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Export Data</span>
              </template>
              <a-button @click="exportExcel">
                <a-icon type="file-excel" class="text-success"/>
              </a-button>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Print Preview</span>
              </template>
              <a-button @click="exportPdf">
                <a-icon type="printer" class="text-success"/>
              </a-button>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Reorder</span>
              </template>
              <a-button @click="getDataReorder">
                <a-icon type="interaction" class="text-success"/>
              </a-button>
            </a-tooltip>
          </a-button-group>
        </template>
            <!-- <v-select
                ref="barang_id"
                placeholder="Ketikan Nama Barang"
                class="style-chooser"
                label="label"
                style="width: 300px;margin-top: -15px;margin-bottom: 10px"
                v-model="idbarang"
                :options="masterBarang"
                :reduce="(tes) => tes.id"
                @input="selectDataBarang"
              /> -->
        <a-table
        style="margin: 0px -25px;margin-top: -25px;"
          :columns="columns"
          :dataSource="data"
          :customRow="customRow"
          :pagination="{
            hideOnSinglePage:true,
            defaultPageSize: 10000000,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }"
          size="small"
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px;"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`Search ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px;"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            >
              Search
            </a-button>
            <a-button
              size="small"
              style="width: 90px;"
              @click="() => handleReset(clearFilters)"
            >
              Reset
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          ></a-icon>
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>
              {{ text }}
            </template>
          </template>
          <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
          <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
          <!-- <span slot="reorder" slot-scope="text">
            <a-tag :color="text === 1 ? 'green' : 'red'">{{ text === 1 ? 'Reorder' : 'Tidak' }}</a-tag>
          </span> -->
          <span slot="action" slot-scope="text, record">
            <a href="javascript:void(0)" class="text-warning" @click="showModal('update', record)">
              <a-icon type="edit"></a-icon>
            </a>
            <a-divider type="vertical" />
            <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
              <a-icon type="delete"></a-icon>
            </a>
            <!-- <a href="javascript:;" class="ant-dropdown-link">
              More actions <a-icon type="down" />
            </a> -->
          </span>
          <span slot="formatCurrency" slot-scope="text">
            {{ formatCurrency(text) }}
          </span>
          <span slot="formatDate" slot-scope="text">
            {{ formatDate(text) }}
          </span>
        </a-table>
      </a-card>
      <a-modal title="Kartu Stok" v-model="visibilitykartustokmodal" :dialogStyle="{ top: '10px' }" :width="800">
        <template slot="footer">
          <a-button class="btn btn-outline-danger" @click="visibilitykartustokmodal = false">Close</a-button>
        </template>
          <div style="margin-top: -15px;"><strong style="margin-top: -10px">Kode: </strong>{{ modalactivekode ?? '-' }}</div>
          <div><strong>Nama Barang: </strong>{{ modalactivenamabarang ?? '-' }}</div>
        <a-table
        v-if="idbarang !== '' && idbarang !== null"
        style="margin: 0 -24px;margin-top: 15px; margin-bottom: -24px;"
        :loading="loadingRefreshDetail"
          :columns="columns1"
          :dataSource="data1"
          :pagination="{
            hideOnSinglePage:true,
            defaultPageSize: 10000000,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }"
          size="small"
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px;"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`Search ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px;"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            >
              Search
            </a-button>
            <a-button
              size="small"
              style="width: 90px;"
              @click="() => handleReset(clearFilters)"
            >
              Reset
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          ></a-icon>
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>
              {{ text }}
            </template>
          </template>
          <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
          <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
          <!-- <span slot="reorder" slot-scope="text">
            <a-tag :color="text === 1 ? 'green' : 'red'">{{ text === 1 ? 'Reorder' : 'Tidak' }}</a-tag>
          </span> -->
          <span slot="action" slot-scope="text, record">
            <a href="javascript:void(0)" class="text-warning" @click="showModal('update', record)">
              <a-icon type="edit"></a-icon>
            </a>
            <a-divider type="vertical" />
            <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
              <a-icon type="delete"></a-icon>
            </a>
            <!-- <a href="javascript:;" class="ant-dropdown-link">
              More actions <a-icon type="down" />
            </a> -->
          </span>
          <span slot="formatCurrency" slot-scope="text">
            {{ formatCurrency(text) }}
          </span>
          <span slot="formatDate" slot-scope="text">
            {{ formatDate(text) }}
          </span>
        </a-table>
      </a-modal>
      <a-modal v-model="formvisible" title="Form Kartu Stok" :dialogStyle="{ top: '10px' }">
        <template slot="footer">
          <a-button class="btn btn-outline-danger" @click="handleCancel">Cancel</a-button>
          <a-button class="btn btn-outline-primary" @click="handleSubmit">Submit</a-button>
        </template>
        <div class="row">
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
            <label class="">Nama</label>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <a-input class="" v-model="modalInput.nama" placeholder="Masukan Nama"></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
            <label class="">Kode</label>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
            <a-input class="" v-model="modalInput.kode" placeholder="Masukan Kode"></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
            <label class="">Satuan</label>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
            <a-input class="" v-model="modalInput.satuan" placeholder="Masukan Satuan"></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
            <label class="">Harga</label>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <cleave
                ref="harga"
                :options="{ numeral: true }"
                class="ant-input"
                :raw="true"
                style="text-align: right; width: 200px"
                v-model="modalInput.harga"
                placeholder="Masukan Harga"
              />
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
            <label class="">Harga Pokok</label>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <cleave
                ref="harga"
                :options="{ numeral: true }"
                class="ant-input"
                :raw="true"
                style="text-align: right; width: 200px"
                v-model="modalInput.harga_pokok"
                placeholder="Masukan Harga Pokok"
              />
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
            <label class="">Rek. Penjualan</label>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                ref="AkunPenjualan"
                placeholder="Pilih Akun Penjualan"
                class="style-chooser"
                label="label"
                style="width: 100%;"
                v-model="modalInput.akun_penjualan"
                :options="masterAkuntansi.biaya"
                :reduce="(tes) => tes.kode"
              />
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
            <label class="">Rek. Persediaan</label>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                ref="AkunPersediaan"
                placeholder="Pilih Akun Persediaan"
                class="style-chooser"
                label="label"
                style="width: 100%;"
                v-model="modalInput.akun_persediaan"
                :options="masterAkuntansi.aktiva"
                :reduce="(tes) => tes.kode"
              />
          </div>
        </div>
      </a-modal>
    </div>
  </template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import NProgress from 'nprogress'
import Cleave from 'vue-cleave-component'
// import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  // {
  //   title: 'Action',
  //   scopedSlots: { customRender: 'action' },
  //   fixed: 'left',
  //   width: 70,
  // },
  {
    title: 'Kode',
    dataIndex: 'kode',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.kode.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.nama.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Saldo',
    dataIndex: 'saldo',
    width: 100,
    align: 'right',
    scopedSlots: {
      customRender: 'formatCurrency',
    },
    sorter: (a, b) => a.saldo - b.saldo,
  },
  {
    title: 'Satuan',
    dataIndex: 'satuan',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.satuan.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Harga Beli',
    dataIndex: 'harga_beli',
    width: 100,
    align: 'right',
    scopedSlots: {
      customRender: 'formatCurrency',
    },
    sorter: (a, b) => a.harga_beli - b.harga_beli,
  },
  {
    title: 'Nilai',
    dataIndex: 'nilai',
    width: 100,
    align: 'right',
    scopedSlots: {
      customRender: 'formatCurrency',
    },
    sorter: (a, b) => a.nilai - b.nilai,
  },
  {
    title: 'ReOrder',
    dataIndex: 'reorder',
    width: 100,
    align: 'right',
    scopedSlots: {
      customRender: 'formatCurrency',
    },
    sorter: (a, b) => a.reorder - b.reorder,
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  components: {
    cleave: Cleave,
  },
  computed: {
    // ...mapState(['settings', 'user']),
  },
  created () {
    this.getMaster()
    // this.getAllData()
  },
  /* Data variable vue */
  data() {
    return {
      visibilitykartustokmodal: false,
      modalactivekode: '',
      modalactivenamabarang: '',
      idbarang: '',
      masterBarang: [],
      loadingRefresh: false,
      loadingRefreshDetail: false,
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'Tabletabungan',
      visible: false,
      allMaster: [],
      masterDataBarang: [],
      masterDataKategori: [],
      data,
      columns,
      columns1: [
        // {
        //   title: 'Action',
        //   scopedSlots: { customRender: 'action' },
        //   fixed: 'left',
        //   width: 70,
        // },
        {
          title: 'Tanggal',
          dataIndex: 'tgl',
          width: 100,
          // fixed: 'left',
          scopedSlots: {
            customRender: 'formatDate',
          },
        },
        {
          title: 'Faktur',
          dataIndex: 'faktur',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.faktur.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Qty Masuk',
          dataIndex: 'qty_masuk',
          width: 100,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.qty_masuk - b.qty_masuk,
        },
        {
          title: 'Qty Keluar',
          dataIndex: 'qty_keluar',
          width: 100,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.qty_keluar - b.qty_keluar,
        },
        {
          title: 'Saldo',
          dataIndex: 'saldo',
          width: 100,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.saldo - b.saldo,
        },
      ],
      data1: [],
      datenow: '',
      componentKey: 0,

      // Modal property
      action: null,
      formvisible: false,
      masterAkuntansi: {
        aktiva: [],
        biaya: [],
      },
      editdata: {},
      modalInput: {
        nama: '',
        kode: '',
        barang_id: '',
        kategori_id: '',
        satuan: '',
        harga: '',
        harga_pokok: '',
        akun_penjualan: '',
        akun_persediaan: '',
        reorder: '0',
      },
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-21
    // localStorage.removeItem('newkoperasiedittabungandata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    // this.getDataMaster()
  },
  methods: {
    moment,
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            console.log('record', record)
            console.log('index', index)
            console.log('event', event)
            this.idbarang = record.barang_id
            this.modalactivekode = record.kode
            this.modalactivenamabarang = record.nama
            this.getAllData()
            this.visibilitykartustokmodal = true
            // this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    async exportPdf() {
      // this.amodal.selectedIndex = i
      if (this.idbarang !== null && this.idbarang !== '') {
        var imbuhan = this.idbarang !== null && this.idbarang !== '' ? '?id=' + this.idbarang : ''
        var res = await lou.ngetDocumentPdf('retail/laporan/stok' + imbuhan + '&preview=pdf', false, false)
        if (res) {
          // console.log("Success", response);
          const blob = new Blob([res], {
            type: 'application/pdf',
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.target = '_blank'
          // link.download = filename
          link.click()
          // const objectUrl = URL.createObjectURL(blob)
          // console.log('objectUrl', objectUrl)
          // this.amodal.ArrayBuffer = objectUrl
          URL.revokeObjectURL(link.href)
          setTimeout(() => {
            this.componentKey += 1
            // this.amodalvisible = true
          }, 500)
        }
        // console.log('record.url2_invoice', record.url2_invoice)
        // window.open(record.url2_invoice, '_blank').focus()
      } else {
        lou.shownotif('Peringatan!', 'Tidak ada data barang yang dipilih!', 'error')
      }
    },
    showModal(action, data) {
      this.getDataMaster()
      this.formvisible = true
      this.action = action
      if (action === 'update') {
        this.modalInput = this.$g.clone(data)
        this.editdata = data
      }
    },
    selectDataBarang() {
      this.getAllData()
    },
    async getDataReorder() {
      var res = await lou.customUrlGet2('retail/laporan/stok?restok=true')
      if (res) {
        this.data = []
        res.data.forEach(element => {
          var rd = {
            ...element,
          }
          this.data.push(rd)
        })
      }
    },
    async getMaster() {
      var res = await lou.customUrlGet2('retail/laporan/stok')
      if (res) {
        this.data = []
        res.data.forEach(element => {
          var rd = {
            ...element,
          }
          this.data.push(rd)
        })
      }
    },
    async getAllData(onclick = false) {
      this.loadingRefreshDetail = true
      var imbuhan = this.idbarang !== null && this.idbarang !== '' ? '?barang_id=' + this.idbarang : ''
      if (this.idbarang !== null && this.idbarang !== '') {
        var response = await lou.customUrlGet2('retail/laporan/stok/kartu' + imbuhan)
        if (response) {
          this.data1 = response.data
          if (onclick) {
            lou.shownotif('Refresh', 'Data telah di refresh.')
          }
          setTimeout(() => {
            this.loadingRefreshDetail = false
          }, 450)
        } else {
          setTimeout(() => {
            this.loadingRefreshDetail = false
          }, 450)
        }
      } else {
        lou.shownotif('Info', 'Silahkan pilih data barang.')
      }
      // console.log('this.data1', this.data1)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('retail/laporan/stok/' + deldata.id)
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    // Modal function
    async handleSubmit() {
      var fd = this.$g.clone(this.modalInput)
      var res = false
      if (this.action === 'update') {
        res = await lou.customUrlPut2('retail/laporan/stok', fd)
      } else {
        res = await lou.customUrlPost2('retail/laporan/stok', fd)
      }
      if (res) {
        this.getAllData()
        this.handleCancel()
      }
    },
    handleCancel() {
      this.formvisible = false
      this.action = 'create'
      this.modalInput = {
        nama: '',
        kode: '',
        barang_id: '',
        kategori_id: '',
        satuan: '',
        harga: '',
        harga_pokok: '',
        akun_penjualan: '',
        akun_persediaan: '',
        reorder: '0',
      }
    },
    async exportExcel() {
      NProgress.start()
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columns)
      var tdata = this.$g.clone(this.data)
      var data = []
      tdata.forEach(element => {
        var retv = {
          ...element,
          tgl: element.tgl === null ? '' : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            } else {
              worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Kartu Stok.xlsx'
      NProgress.done()
      link.click()
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
  },
}
</script>

  <style scoped>
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
  </style>
